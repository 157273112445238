<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-03-28 12:41:00
 * @ Description: Component displaying the What's Included section on the landing page.
 -->

<template>
    <!-- Mobile -->
    <v-row
        align="center"
        justify="center"
        style="height: 100%; color: #2E567A;"
        v-if="$vuetify.breakpoint.smAndDown"
        id="landing-solutions"
    >
        <v-col
            cols="12"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
        >
            <v-row>
                <!-- Heading -->
                <v-col
                    class="mont-40-eb"
                    cols="12"
                >
                    What's <br />
                    included
                </v-col>
                <!-- Heading -->

                <!-- Pricing Carousel -->
                <v-col
                    cols="12"
                    class="pa-0"
                >
                    <v-carousel
                        :show-arrows="false"
                        hide-delimiter-background
                        light
                        height="auto"
                        cycle
                        interval="5000"
                        class="pb-16"
                        v-model="mobileCarousel"
                        @change="findFittextFontSize"
                    >
                        <v-carousel-item
                            v-for="(item, index) in carouselData"
                            :key="index"
                            eager
                        >
                            <v-row
                                class="pa-6"
                            >
                                <v-col
                                    cols="12"
                                    class="pa-0"
                                    style="color: #2E567A;"
                                >
                                    <!-- Package Name -->
                                    <span
                                        class="mont-25-eb"
                                        v-html="item.name"
                                    />
                                    <!-- Package Name -->

                                    <div
                                        class="pt-6"
                                    >
                                        <v-row
                                            v-for="(lineItem, lineIndex) in item.product"
                                            :key="lineIndex"
                                            style="height: 85px;"
                                        >
                                            <!-- Item Heading -->
                                            <v-col
                                                cols="6"
                                                class="rob-14-r pa-0 pl-2"
                                                style="display: flex; align-items: center; height: 100%; border-right: #2E567A solid 1px;"
                                                :style="lineIndex % 2 === 0 ? 'background: rgba(243, 170, 99, 0.11);' : ''"
                                                v-html="lineItem.name"
                                            >
                                            </v-col>
                                            <!-- Item Heading -->

                                            <!-- Item Data -->
                                            <v-col
                                                cols="6"
                                                class="rob-14-r pa-0 text-center"
                                                style="display: flex; justify-content: center; align-items: center; height: 100%;"
                                                :style="lineIndex % 2 === 0 ? 'background: rgba(240, 243, 246, 1);' : ''"
                                            >
                                                <v-img
                                                    src="/landing/main/included/LandingMainIncludedCheck.png"
                                                    width="auto"
                                                    height="50px"
                                                    contain
                                                    v-if="!lineItem.price"
                                                />
                                                <div
                                                    v-else
                                                    style="max-width: calc(100% - 24px);"
                                                >
                                                    <div
                                                        style="display: flex; justify-content: center; font-weight: 600; max-width: 100%; overflow-x: auto; font-size: 14px;"
                                                        :id="'fittext-' + index + '-' + lineIndex"
                                                    >
                                                        {{ lineItem.price }}
                                                    </div>
                                                    <div
                                                        style="display: flex; justify-content: center;"
                                                    >
                                                        {{ lineItem.bottomText }}
                                                    </div>
                                                </div>
                                            </v-col>
                                            <!-- Item Data -->
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <!-- Pricing Carousel -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Mobile -->

    <!-- Desktop -->
    <v-row
        style="height: 100%; color: #2E567A;"
        v-else-if="$vuetify.breakpoint.mdAndUp"
        id="landing-solutions"
        justify="center"
    >
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1110px;' : 'max-width: 920px;'"
        >
            <v-row>
                <!-- Side Text -->
                <v-col
                    cols="3"
                >
                    <!-- Top White Space -->
                    <div
                        style="height: 110px;"
                        :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 200px;' : 'max-width: 135px;'"
                    />
                    <!-- Top White Space -->

                    <!-- Heading -->
                    <span
                        :class="$vuetify.breakpoint.lgAndUp ? 'mont-50-eb' : 'mont-40-eb'">
                        What's <br />
                        included
                    </span>
                    <!-- Heading -->

                    <br />
                    <br />

                    <!-- Text -->
                    <span
                        :class="$vuetify.breakpoint.lgAndUp ? 'rob-20-m' : 'rob-18-m'"
                    >
                        Take the hassle out <br /> of job searching and <br /> recruiting, and <br /> effortlessly bring the <br /> business world to <br /> your desktop, tablet <br /> and mobile phone <br /> with the innovative <br /> Fixer platform.
                    </span>
                    <!-- Text -->

                    <br />

                    <!-- Get Started Button -->
                    <v-btn
                        class="white--text no-uppercase rounded-lg mt-8 mb-8 mt-12 mb-12 ml-auto rob-18-m"
                        color="#FFA858"
                        style="height: 58px; width: 177px;"
                        @click="showSignupOverlay"
                    >
                        Get Started
                    </v-btn>
                    <!-- Get Started Button -->
                </v-col>
                <!-- Side Text -->

                <!-- Pricing Table -->
                <v-col
                    cols="9"
                >
                    <v-row
                        justify="center"
                    >
                        <!-- Left Table Headings -->
                        <v-col
                            style="color: #2E567A;"
                            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 200px;' : 'max-width: 135px;'"
                            class="pa-0"
                        >
                            <div
                                class="ml-n3"
                                style="height: 110px;"
                                :style="$vuetify.breakpoint.lgAndUp ? 'width: 200px;' : 'width: 135px;'"
                            />
                            <div>
                                <v-row
                                    v-for="(lineItem, lineIndex) in tableItems"
                                    :key="lineIndex"
                                    style="height: 85px;"
                                    :style="$vuetify.breakpoint.lgAndUp ? 'width: 200px;' : 'width: 135px;'"
                                    class="mr-0"
                                >
                                    <v-col
                                        cols="12"
                                        class="pa-0 pl-4"
                                        :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-r' : 'rob-14-r'"
                                        style="max-width: 200px; display: flex; align-items: center; height: 100%;"
                                        :style="lineIndex % 2 === 0 && $vuetify.breakpoint.lgAndUp ? 'background: rgba(243, 170, 99, 0.11); max-width: 200px;' : lineIndex % 2 === 0 && $vuetify.breakpoint.md ? 'background: rgba(243, 170, 99, 0.11); max-width: 135px;' : $vuetify.breakpoint.md ? 'max-width: 135px;' : 'max-width: 200px;'"
                                        v-html="lineItem.name"
                                    >
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <!-- Left Table Headings -->

                        <v-col
                            style="color: #2E567A;"
                            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 200px;' : 'max-width: 135px;'"
                            v-for="(item, index) in carouselData"
                            :key="index"
                            class="pa-0"
                        >
                            <!-- Top Table Headings -->
                            <div
                                style="height: 110px; display: flex; justify-content: center; align-items: center;"
                                :style="index < 4 && $vuetify.breakpoint.lgAndUp ? 'width: 200px; border-left: #2E567A solid 1px;' : index < 4 && $vuetify.breakpoint.md ? 'width: 135px; border-left: #2E567A solid 1px;' : $vuetify.breakpoint.lgAndUp ? 'width: 200px;' : 'width: 135px;'"
                                class="text-center ml-n3"
                                :class="$vuetify.breakpoint.lgAndUp ? 'mont-25-eb' : 'mont-20-eb'"
                                v-html="item.name"
                            />
                            <!-- Top Table Headings -->

                            <!-- Table Data -->
                            <div>
                                <v-row
                                    v-for="(lineItem, lineIndex) in item.product"
                                    :key="lineIndex"
                                    style="height: 85px;"
                                    :style="$vuetify.breakpoint.lgAndUp ? 'width: 200px;' : 'width: 135px;'"
                                >
                                    <v-col
                                        cols="12"
                                        class="pa-0 text-center"
                                        :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-r' : 'rob-14-r'"
                                        style="display: flex; justify-content: center; align-items: center; height: 100%;"
                                        :style="lineIndex % 2 === 0 && index < 4 ? 'background: rgba(240, 243, 246, 1); border-left: #2E567A solid 1px;' : lineIndex % 2 === 0 ? 'background: rgba(240, 243, 246, 1);' : index < 4 ? 'border-left: #2E567A solid 1px;' : ''"
                                    >
                                        <v-img
                                            src="/landing/main/included/LandingMainIncludedCheck.png"
                                            height="44px"
                                            contain
                                            v-if="!lineItem.price"
                                        />
                                        <div
                                            v-else
                                            style="max-width: calc(100% - 24px);"
                                        >
                                            <div
                                                style="display: flex; justify-content: center; font-weight: 600; max-width: 100%; overflow-x: auto;"
                                                :style="$vuetify.breakpoint.lgAndUp ? 'font-size: 16px;' : 'font-size: 14px;'"
                                                :id="'fittext-' + index + '-' + lineIndex"
                                            >
                                                {{ lineItem.price }}
                                            </div>
                                            <div
                                                style="display: flex; justify-content: center;"
                                            >
                                                {{ lineItem.bottomText }}
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <!-- Table Data -->
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Pricing Table -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Desktop -->
</template>
<script>
    import { LandingSubscriptionController } from '@/logic/controller/index.controller.js' 

    export default {
        name: 'LandingIncludedComponent',

        props: {
            subscriptionType: {
                type: String,
                default: 'Individual',
                required: true
            }
        },

        computed: {
            carouselData () {
                if (this.subscriptionType === 'Company') return this.data.company
                else return this.data.individual
            },

            // removeTrialPackage () {
            //     return this.carouselData.filter(function (e) {
            //         return (e.id !== 1 && e.id !== 5)
            //     })
            // },

            tableItems () {
                if (this.subscriptionType === 'Company') return this.data.company[0].product
                else return this.data.individual[0].product
            }
        },

        watch: {
            '$vuetify.breakpoint': {
                handler () {
                    this.$nextTick(() => {
                        this.$forceUpdate()
                    })
                },
                deep: true
            }
        },

        data () {
            return {
                mobileCarousel: 0,
                data: {
                    individual: [
                        {
                            product: []
                        }
                    ],
                    company: [
                        {
                            product: []
                        }
                    ]
                }
            }
        },

        beforeMount () {
            this.landingSubscriptionController = new LandingSubscriptionController()
        },

        async mounted () {
            await this.getSubscriptionData()
        },

        updated () {
            this.$nextTick(() => {
                for (let index = 0; index < this.carouselData.length; index++) {
                    const item = this.carouselData[index]
                    for (let lineIndex = 0; lineIndex < item.product.length; lineIndex++) {
                        var fittext = document.getElementById(`fittext-${index}-${lineIndex}`)
                        if (fittext) this.findFittextFontSize(fittext)
                    }
                }
            })
        },

        methods: {
            async getSubscriptionData () {
                try {
                    this.data = await this.landingSubscriptionController.get()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showSignupOverlay () {
                this.$store.commit('setSignupOverlay', true)
            },

            findFittextFontSize (fittext) {
                this.$nextTick(() => {
                    if (fittext.scrollHeight > fittext.clientHeight) {
                        this.$nextTick(() => {
                            var fontSize = fittext.style.fontSize
                            var fontSizeNumber = fontSize.split('px')[0]
                            fittext.style.fontSize = `${Number(fontSizeNumber - 1)}px`

                            this.findFittextFontSize(fittext)
                        })
                    } else if (fittext.scrollWidth > fittext.clientWidth) {
                        this.$nextTick(() => {
                            var fontSize = fittext.style.fontSize
                            var fontSizeNumber = fontSize.split('px')[0]
                            fittext.style.fontSize = `${Number(fontSizeNumber - 1)}px`

                            this.findFittextFontSize(fittext)
                        })
                    }
                })
            }
        }
    }
</script>

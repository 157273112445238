<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-22 13:33:16
 * @ Description: Component displaying the What Is Fixer section on the landing page.
 -->

<template>
    <!-- Mobile -->
    <v-row
        align="center"
        justify="center"
        style="height: 100%; color: #2E567A;"
        v-if="$vuetify.breakpoint.smAndDown"
    >
        <v-col
            cols="12"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
        >
            <v-row>
                <!-- Heading and Text -->
                <v-col
                    cols="12"
                    class="mont-40-eb"
                >
                    What is Fixer?
                </v-col>
                <v-col
                    class="rob-14-r"
                    cols="12"
                >
                    Fixer is the online portal that connects <span class="rob-14-b">Job 
                        Seekers</span> with <span class="rob-14-b">Companies</span> that are looking for top 
                    talent.  Fixer provides access to everything from 
                    freelance and remote jobs to permanent careers at 
                    leading global companies. A simple fixed monthly 
                    fee gets you access to jobs, talent &amp; the Fixer 
                    Tender portal. 
                    <br />
                    <br />
                    <span class="rob-14-b">Sign up</span> for a free trial, load your profile or job 
                    specification and let Fixer do the rest.
                </v-col>
                <!-- Heading and Text -->

                <!-- Signup Button -->
                <v-col
                    cols="12"
                    class="text-center pt-8 pb-12"
                >
                    <v-btn
                        class="white--text no-uppercase rounded-lg rob-18-m"
                        style="height: 42px; width: 154px;"
                        color="#FFA858"
                        @click="showSignupOverlay"
                    >
                        Free Sign Up
                    </v-btn>
                </v-col>
                <!-- Signup Button -->

                <!-- Cards Carousel -->
                <v-col
                    cols="12"
                    class="pa-0"
                >
                    <v-carousel
                        :show-arrows="false"
                        hide-delimiter-background
                        light
                        height="auto"
                        cycle
                        interval="5000"
                        class="pb-16"
                    >
                        <v-carousel-item
                            v-for="(item, index) in carouselData"
                            :key="index"
                            eager
                            class="elevation-2 mx-auto"
                            style="max-width: 265px;"
                        >
                            <v-card
                                height="285"
                                max-height="285"
                                width="265"
                                elevation="0"
                                max-width="265"
                                class="mx-auto"
                            >
                                <!-- Image -->
                                <v-img
                                    :src="item.image"
                                    :height="item.height"
                                    contain
                                    style="position: absolute; right: 15px; bottom: 50%;"
                                />
                                <!-- Image -->

                                <!-- Heading and Text -->
                                <v-row
                                    style="height: 100%; width: 100%; position: relative;"
                                    class="mx-auto pa-2"
                                >
                                    <v-col
                                        cols="12"
                                        class="mont-30-eb"
                                        v-html="item.heading"
                                        style="font-weight: 600; color: #2E567A; height: 50%;"
                                    >
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="rob-14-r"
                                        style="color: #2E567A; height: 50%;"
                                    >
                                        {{ item.text }}
                                        <br />
                                        <br />

                                        <!-- Signup Button -->
                                        <v-btn
                                            class="no-uppercase ml-n5 rob-14-b"
                                            style="color: #2E567A; position: absolute; bottom: 10px;"
                                            :style="$vuetify.breakpoint.smAndUp ? 'bottom: 15px;' : '10px;'"
                                            text
                                            @click="showSignupOverlay"
                                        >
                                            Sign Up
                                        </v-btn>
                                        <!-- Signup Button -->
                                    </v-col>
                                </v-row>
                                <!-- Heading and Text -->
                            </v-card>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <!-- Cards Carousel -->

                <!-- Trusted Brands Carousel -->
                <v-col
                    cols="12"
                    class="rob-14-r"
                >
                    Trusted by
                </v-col>
                <v-col
                    class="px-0 pt-0"
                >
                    <v-carousel
                        hide-delimiters
                        height="auto"
                        light
                    >
                        <v-carousel-item
                            eager
                            class="ma-8"
                            v-for="(item, index) in scrollBannerMobileImages"
                            :key="index"
                        >
                            <v-row
                                align="center"
                                justify="center"
                            >
                                <v-col>
                                    <v-img
                                        :src="item.image"
                                        height="40px"
                                        width="auto"
                                        max-width="60%"
                                        contain
                                        eager
                                        class="mx-auto"
                                    />
                                </v-col>
                            </v-row>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <!-- Trusted Brands Carousel -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Mobile -->

    <!-- Desktop -->
    <v-row
        align="center"
        justify="center"
        style="color: #2E567A;"
        v-else-if="$vuetify.breakpoint.mdAndUp"
    >
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1110px;' : 'max-width: 920px;'"
        >
            <!-- Trusted Brands Carousel -->
            <v-row
                align="center"
                class="pa-4"
            >
                <v-col
                    cols="1"
                    class="pa-0 rob-14-r"
                >
                    Trusted by
                </v-col>
                <v-col
                    class="pa-0"
                >
                    <v-carousel
                        hide-delimiters
                        height="auto"
                        light
                    >
                        <v-carousel-item
                            eager
                            class="ma-8"
                            v-for="(item, index) in scrollBannerImages"
                            :key="index"
                        >
                            <v-row>
                                <v-col
                                    v-for="(image, imageIndex) in item.images"
                                    :key="imageIndex"
                                >
                                    <v-img
                                        :src="image.image"
                                        :height="$vuetify.breakpoint.xl ? '50px' : $vuetify.breakpoint.lg ? '45px' : '40px'"
                                        width="auto"
                                        max-width="60%"
                                        contain
                                        eager
                                        class="mx-auto"
                                    />
                                </v-col>
                            </v-row>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
            </v-row>
            <!-- Trusted Brands Carousel -->

            <!-- Heading and Text -->
            <v-row
                class="pa-4"
            >
                <v-col
                    cols="12"
                    class="text-center mont-60-eb"
                    :class="$vuetify.breakpoint.lgAndUp ? 'mont-60-eb' : 'mont-50-eb'"
                >
                    What is Fixer?
                </v-col>
                <v-col
                    cols="12"
                    class="text-center"
                    :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-r' : 'rob-14-r'"
                >
                    Fixer is the online portal that connects <span :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-b' : 'rob-14-b'">Job Seekers</span> with <span :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-b' : 'rob-14-b'">Companies</span> that are looking for top <br />
                    talent.  Fixer provides access to everything from freelance and remote jobs to permanent <br />
                    careers at leading global companies. A simple fixed monthly fee gets you access to <br /> 
                    jobs &amp; talent. 
                    <br />
                    <br />
                    <span :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-b' : 'rob-14-b'">Sign up</span> for a free trial, load your profile or job specification and let Fixer do the rest.
                </v-col>
            </v-row>
            <!-- Heading and Text -->

            <!-- Cards -->
            <v-row
                class="pa-4"
                align="center"
            >
                <v-col
                    cols="4"
                    v-for="(item, index) in carouselData"
                    :key="index"
                    class="pt-8"
                >
                    <v-card
                        height="270"
                        max-height="270"
                        elevation="0"
                        :width="$vuetify.breakpoint.lgAndUp ? '320' : '280'"
                        :max-width="$vuetify.breakpoint.lgAndUp ? '320' : '280'"
                        class="mx-auto"
                    >
                        <!-- Image -->
                        <v-img
                            :src="item.image"
                            :height="item.height"
                            contain
                            style="position: absolute; right: 15px; bottom: 50%;"
                        />
                        <!-- Image -->

                        <!-- Heading and Text -->
                        <v-row
                            style="height: 100%; width: 100%; position: relative;"
                            class="elevation-2 mx-auto pa-2"
                        >
                            <v-col
                                cols="12"
                                :class="$vuetify.breakpoint.lgAndUp ? 'mont-40-eb' : 'mont-35-eb'"
                                v-html="item.heading"
                                style="font-weight: 600; color: #2E567A; height: 52.5%;"
                            >
                            </v-col>
                            <v-col
                                cols="12"
                                :class="$vuetify.breakpoint.xl ? 'rob-14-r' : 'rob-12-r'"
                                style="color: #2E567A; height: 47.5%;"
                            >
                                {{ item.text }}
                                <br />
                                <br />

                                <!-- Signup Button -->
                                <v-btn
                                    class="no-uppercase ml-n5"
                                    :class="$vuetify.breakpoint.lgAndUp ? 'rob-14-b' : 'rob-12-b'"
                                    style="color: #2E567A; position: absolute; bottom: 10px;"
                                    :style="$vuetify.breakpoint.smAndUp ? 'bottom: 15px;' : '10px;'"
                                    text
                                    @click="showSignupOverlay"
                                >
                                    Sign Up
                                </v-btn>
                                <!-- Signup Button -->
                            </v-col>
                        </v-row>
                        <!-- Heading and Text -->
                    </v-card>
                </v-col>

                <!-- Signup Button -->
                <v-col
                    class="text-center"
                    cols="8"
                >
                    <div
                        class="mont-60-eb"
                    >
                        Sign Up Today
                    </div>
                    <div
                        :class="$vuetify.breakpoint.xl ? 'rob-14-r' : 'rob-12-r'"
                        class="py-6"
                    >
                        <span style="font-weight: bold;">Sign up</span> for a free trial, load your profile or job specification and let Fixer do the rest.
                    </div>
                    <v-btn
                        class="white--text no-uppercase rounded-lg rob-18-m"
                        color="#FFA858"
                        @click="showSignupOverlay"
                        style="width: 177px; height: 58px;"
                    >
                        Free Sign Up
                    </v-btn>
                </v-col>
                <!-- Signup Button -->
            </v-row>
            <!-- Cards -->
        </v-col>
    </v-row>
    <!-- Desktop -->
</template>
<script>
    export default {
        name: 'LandingWhatIsFixerComponent',

        data () {
            return {
                carouselData: [
                    {
                        heading: 'Find<br />Work',
                        text: 'Looking for a freelance, remote, part-time or permanent position? Fixer has you covered.',
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerCarousel_1.png',
                        height: 117
                    },
                    {
                        heading: 'Post<br />Jobs',
                        text: 'Register your company and start hiring today. Top talent available across multiple industries.',
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerCarousel_2.png',
                        height: 120
                    },
                    {
                        heading: 'Professional<br />Services',
                        text: 'Choose from a wide selection of lifestyle services whenever you need it or post your own',
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerCarousel_5.png',
                        height: 69
                    },
                    // {
                    //     heading: 'Bid or<br />Post<br />Tenders',
                    //     text: 'Access and manage you projects, applications, jobs and everything else in the Fixer Portal.',
                    //     image: '/landing/main/whatisfixer/LandingMainWhatIsFixerCarousel_3.png',
                    //     height: 125
                    // },
                    {
                        heading: 'Business<br />in Your<br />Pocket',
                        text: 'Take the hassle out job searching and recruiting, and bring the business world to your desktop, tablet and mobile phone.',
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerCarousel_4.png',
                        height: 99
                    }
                ],
                scrollBannerImages: [
                    {
                        images: [
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_1.png'
                            },
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_2.png'
                            },
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_3.png'
                            },
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_4.png'
                            }
                        ]
                    },
                    {
                        images: [
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_5.png'
                            },
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_6.png'
                            },
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_7.png'
                            },
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_8.png'
                            }
                        ]
                    },
                    {
                        images: [
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_9.png'
                            },
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_10.png'
                            },
                            {
                                image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_11.png'
                            }
                        ]
                    }
                ],
                scrollBannerMobileImages: [
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_1.png'
                    },
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_5.png'
                    },
                
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_6.png'
                    },
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_7.png'
                    },
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_8.png'
                    },
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_4.png'
                    },
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_9.png'
                    },
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_10.png'
                    },
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_11.png'
                    },
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_3.png'
                    },
                    {
                        image: '/landing/main/whatisfixer/LandingMainWhatIsFixerScroll_2.png'
                    }
                ]
            }
        },

        methods: {
            showSignupOverlay () {
                this.$store.commit('setSignupOverlay', true)
            }
        }
    }
</script>
<style scoped>
    >>> .v-window__prev, >>> .v-window__next {
        margin-left: 1px;
        margin-right: 1px;
    }
</style>

<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-22 13:30:24
 * @ Description: Component displaying the Main section on the landing page.
 -->

<template>
    <!-- Mobile -->
    <v-row
        align="center"
        style="height: 100%; padding-top: 70px;"
        v-if="$vuetify.breakpoint.smAndDown"
        class="white--text"
    >
        <v-col
            cols="12"
            class="pa-0"
        >
            <!-- Background Images -->
            <v-carousel
                hide-delimiters
                :show-arrows="false"
                height="auto"
                cycle
                interval="10000"
            >
                <!-- Text On Images -->
                <v-col
                    cols="12"
                    style="z-index: 1; height: 100%; position: absolute; display: flex; justify-content: center;"
                    class="pb-6"
                >
                    <v-row
                        :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
                        style="height: 100%;"
                        align="end"
                    >
                        <v-col>
                            <v-row
                                class="mont-40-eb"
                            >
                                Your <br />
                                Business in <br />
                                Your <br />
                                Pocket
                            </v-row>
                            <v-row
                                class="pt-4"
                                :class="$store.state.general.customBreakpoints.xxs ? 'rob-125-b' : 'rob-14-b'"
                            >
                                Find Work. Post Jobs. Access Services.
                            </v-row>
                            <v-row
                                style="line-height: 1.5;"
                                :class="$store.state.general.customBreakpoints.xxs ? 'rob-125-r' : 'rob-14-r'"
                            >
                                Take the hassle out of job searching and <br />
                                recruiting, and effortlessly bring the business <br />
                                world to your desktop, tablet and mobile phone.
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Text On Images -->

                <!-- Images -->
                <v-carousel-item
                    v-for="(item, index) in backgroundImages"
                    :key="index"
                    eager
                >
                    <v-img
                        :src="item.mobile"
                        width="100vw"
                        eager
                    >
                    </v-img>
                </v-carousel-item>
                <!-- Images -->
            </v-carousel>
            <!-- Background Images -->
        </v-col>

        <v-col
            style="display: flex; justify-content: center;"
        >
            <v-row
                align="center"
                justify="center"
                style="margin-top: -37px;"
                :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
            >
                <!-- Search Input and Button -->
                <v-col
                    cols="12"
                    class="text-center px-3 pb-8"
                    style="z-index: 1;"
                >
                    <v-row>
                        <input
                            type="text"
                            placeholder='Try "Social Media Jobs"'
                            style="caret-color: black; background-color: white; height: 50px; width: calc(100% - 65px); box-shadow: 0 4px 30px -10px gray;"
                            class="rounded-l-lg pl-2 rob-16-r"
                            v-model="searchText"
                        >
                        <v-btn
                            class="white--text no-uppercase rounded-r-lg"
                            color="#FFA858"
                            style="box-shadow: 0 4px 30px -10px gray; z-index: 2; height: 50px; width: 50px;"
                            aria-label="search"
                            @click="$router.push({ path: '/browse/jobs', query: { search: searchText } })"
                        >
                            <v-icon>
                                {{ mdiMagnify }}
                            </v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
                <!-- Search Input and Button -->

                <!-- Browse Buttons -->
                <v-col
                    cols="6"
                    class="text-right pa-0 pr-1"
                >
                    <v-btn
                        class="white--text no-uppercase rounded-lg rob-16-m"
                        color="#2E567A"
                        style="height: 34px;"
                        block
                        @click="$router.push('/browse/talent')"
                    >
                        Browse Talent
                    </v-btn>
                </v-col>
                <v-col
                    cols="6"
                    class="pa-0 pl-1"
                >
                    <v-btn
                        class="white--text no-uppercase rounded-lg rob-16-m"
                        color="#2E567A"
                        style="height: 34px;"
                        block
                        @click="$router.push('/browse/jobs')"
                    >
                        Browse Jobs
                    </v-btn>
                </v-col>
                <!-- Browse Buttons -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Mobile -->

    <!-- Desktop -->
    <v-row
        align="center"
        justify="center"
        style="position: relative;"
        v-else-if="$vuetify.breakpoint.mdAndUp"
        class="white--text"
    >
        <!-- Content On Images -->
        <v-col
            cols="12"
            style="z-index: 1; position: absolute;"
            :style="$vuetify.breakpoint.lgAndUp ? 'margin-top: 160px; max-width: 1110px;' : 'margin-top: 70px; max-width: 920px;'"
            class="pa-4"
        >
            <!-- Text On Images -->
            <div
                :class="$vuetify.breakpoint.lgAndUp ? 'mont-60-eb' : 'mont-50-eb'"
            >
                Your Business <br />
                in Your Pocket
            </div>
            <div
                :class="$vuetify.breakpoint.lgAndUp ? 'rob-18-b' : 'rob-16-b'"
            >
                Find Work. Post Jobs. Access Services.
            </div>
            <div
                :class="$vuetify.breakpoint.lgAndUp ? 'rob-18-r' : 'rob-16-r'"
            >
                Take the hassle out of job searching and recruiting, and effortlessly <br />
                bring the business world to your desktop, tablet and mobile phone <br />
                with Fixer.
            </div>
            <!-- Text On Images -->

            <!-- Search Input and Button -->
            <div
                style="position: relative; display: flex; align-items: center;"
                class="pt-8"
            >
                <input
                    type="text"
                    placeholder='Try "Social Media Jobs"'
                    style="height: 58px; caret-color: black; background-color: white; width: 394px;"
                    class="rounded-l-lg pl-16 rob-20-r"
                    v-model="searchText"
                >
                <v-icon
                    style="position: absolute; left: 0; top: calc(50% + 16px); transform: translateY(-50%);"
                    color="#2E567A"
                    class="pl-4"
                >
                    {{ mdiMagnify }}
                </v-icon>
                <v-btn
                    class="white--text no-uppercase rounded-r-lg ml-n1 rob-18-m"
                    color="#FFA858"
                    style="height: 58px; width: 128px;"
                    @click="$router.push({ path: '/browse/jobs', query: { search: searchText } })"
                >
                    Search
                </v-btn>
            </div>
            <!-- Search Input and Button -->

            <!-- Signup and Browse Buttons -->
            <div
                class="pt-6"
            >
                <v-btn
                    class="white--text no-uppercase rounded-lg mr-3 rob-18-m"
                    color="#FFA858"
                    @click="showSignupOverlay"
                    style="height: 58px; width: 177px;"
                >
                    Free Sign Up
                </v-btn>
                <v-btn
                    class="white--text no-uppercase rounded-lg mr-3 rob-18-m"
                    color="#2E567A"
                    style="height: 58px; width: 164px;"
                    @click="$router.push('/browse/talent')"
                >
                    Browse Talent
                </v-btn>
                <v-btn
                    class="white--text no-uppercase rounded-lg rob-18-m"
                    color="#2E567A"
                    style="height: 58px; width: 154px;"
                    @click="$router.push('/browse/jobs')"
                >
                    Browse Jobs
                </v-btn>
            </div>
            <!-- Signup and Browse Buttons -->
        </v-col>
        <!-- Content On Images -->

        <!-- Background Images -->
        <v-col
            cols="12"
            class="pa-0"
        >
            <v-carousel
                hide-delimiters
                :show-arrows="false"
                height="auto"
                cycle
                interval="10000"
            >
                <v-carousel-item
                    v-for="(item, index) in backgroundImages"
                    :key="index"
                    eager
                >
                    <div
                        style="height: 861px; position: absolute;"
                    />
                    <v-img
                        :src="item.desktop"
                        width="100vw"
                        height="100%"
                        eager
                    >
                    </v-img>
                </v-carousel-item>
            </v-carousel>
        </v-col>
        <!-- Background Images -->
    </v-row>
    <!-- Desktop -->
</template>
<script>
    import { mdiMagnify } from '@mdi/js'

    export default {
        name: 'LandingMainComponent',

        data () {
            return {
                backgroundImages: [
                    {
                        desktop: '/landing/main/main/desktop/LandingMainMainDesktopBackground_1.webp',
                        mobile: '/landing/main/main/mobile/LandingMainMainMobileBackground_1.webp'
                    },
                    {
                        desktop: '/landing/main/main/desktop/LandingMainMainDesktopBackground_2.webp',
                        mobile: '/landing/main/main/mobile/LandingMainMainMobileBackground_2.webp'
                    },
                    {
                        desktop: '/landing/main/main/desktop/LandingMainMainDesktopBackground_3.webp',
                        mobile: '/landing/main/main/mobile/LandingMainMainMobileBackground_3.webp'
                    },
                    {
                        desktop: '/landing/main/main/desktop/LandingMainMainDesktopBackground_4.webp',
                        mobile: '/landing/main/main/mobile/LandingMainMainMobileBackground_4.webp'
                    }
                ],
                mdiMagnify,
                searchText: ''
            }
        },

        methods: {
            showSignupOverlay () {
                this.$store.commit('setSignupOverlay', true)
            }
        }
    }
</script>
<style scoped>
    input:focus {
        outline: none;
    }
</style>

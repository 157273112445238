<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-25 14:35:03
 * @ Description: Component displaying the How It Works section on the landing page.
 -->

<template>
    <!-- Mobile -->
    <v-row
        align="center"
        justify="center"
        style="height: 100%; color: #2E567A; background-color: #F0F3F6;"
        v-if="$vuetify.breakpoint.smAndDown"
        id="landing-how-it-works"
        class="py-8"
    >
        <v-col
            cols="12"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
        >
            <v-row>
                <!-- Text and Image -->
                <v-col
                    cols="12"
                    class="rob-18-r"
                >
                    <span
                        style="background-color: #2E567A; color: white;"
                        class="pa-2"
                    >
                        How it works?
                    </span>
                </v-col>
                <v-col
                    class="mont-40-eb"
                    cols="12"
                >
                    Sign Up to <br />
                    Fixer in <br />
                    5 Minutes
                </v-col>
                <!-- Text and Image -->

                <!-- YouTube Video Frame -->
                <v-col
                    cols="12"
                    class="pt-0 pt-sm-3"
                >
                    <v-card
                        :height="$vuetify.breakpoint.xs ? '200px' : '250'"
                        :width="$vuetify.breakpoint.xs ? '265px' : '330'"
                        elevation="0"
                        class="mx-auto"
                    >
                        <iframe
                            src="https://www.youtube.com/embed/RGFrju8njks"
                            height="100%"
                            width="100%"
                            title="How It Works"
                        />
                    </v-card>
                </v-col>
                <!-- YouTube Video Frame -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Mobile -->

    <!-- Desktop -->
    <v-row
        style="color: #2E567A; background-color: #F0F3F6;"
        v-else-if="$vuetify.breakpoint.mdAndUp"
        id="landing-how-it-works"
        justify="center"
        align="end"
    >
        <v-col
            cols="12"
            style="position: relative;"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1110px;' : 'max-width: 920px;'"
        >
            <v-row
                class="pa-4"
            >
                <!-- YouTube Video Frame -->
                <v-col
                    cols="6"
                >
                    <v-card
                        elevation="0"
                        class="mx-auto"
                        height="330px"
                        width="555px"
                    >
                        <iframe
                            src="https://www.youtube.com/embed/RGFrju8njks"
                            height="100%"
                            width="100%"
                            title="How It Works"
                        />
                    </v-card>
                </v-col>
                <!-- YouTube Video Frame -->

                <!-- Text and Image -->
                <v-col
                    cols="6"
                    align-self="end"
                >
                    <v-row>
                        <v-col
                            cols="12"
                            :class="$vuetify.breakpoint.lgAndUp ? 'rob-18-r' : 'rob-16-r'"
                        >
                            <span>
                                How it works?
                            </span>
                        </v-col>
                        <v-col
                            cols="12"
                            :class="$vuetify.breakpoint.lgAndUp ? 'mont-60-eb' : 'mont-51-eb'"
                            style="position: relative;"
                        >
                            From Sign <br />
                            Up to Fixer <br />
                            in 5 minutes
                            <v-img
                                src="/landing/main/howitworks/LandingMainHowItWorks.png"
                                height="186"
                                width="120"
                                contain
                                style="position: absolute; right: 0; bottom: 32px;"
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Text and Image -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Desktop -->
</template>
<script>
    export default {
        name: 'LandingHowItWorksComponent'
    }
</script>

<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-22 13:27:30
 * @ Description: Component displaying the Company section on the landing page.
 -->

<template>
    <!-- Mobile -->
    <v-row
        align="center"
        style="height: 100%; background-color: #F0F3F6;"
        v-if="$vuetify.breakpoint.smAndDown"
        class="white--text"
    >
        <!-- Image Carousel -->
        <v-col
            cols="12"
            class="pa-0"
        >
            <v-carousel
                hide-delimiters
                :show-arrows="false"
                height="auto"
                cycle
                interval="10000"
            >
                <v-col
                    cols="12"
                    style="z-index: 1; height: 100%; position: absolute; display: flex; justify-content: center;"
                >
                    <v-row
                        style="height: 100%; position: relative;"
                        :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 240px;' : $vuetify.breakpoint.xs ? 'max-width: 290px;' : 'max-width: 470px;' "
                        align="end"
                    >
                        <!-- For Companies -->
                        <v-col
                            cols="12"
                        >
                            <v-row
                                class="rob-18-r"
                                style="position: absolute; bottom: 140px;"
                            >
                                <span
                                    style="background-color: #FFA858;"
                                    class="pl-2 pr-2"
                                >
                                    For Companies
                                </span>
                            </v-row>
                        </v-col>
                        <!-- For Companies -->

                        <!-- Heading -->
                        <v-col>
                            <v-row
                                class="mont-35-eb"
                            >
                                Recruit <br />
                                Talent from <br />
                                Anywhere
                            </v-row>
                        </v-col>
                        <!-- Heading -->
                    </v-row>
                </v-col>

                <!-- Image -->
                <v-carousel-item
                    v-for="(item, index) in backgroundImages"
                    :key="index"
                    eager
                >
                    <v-img
                        :src="item.mobile"
                        width="100vw"
                        eager
                    >
                    </v-img>
                </v-carousel-item>
                <!-- Image -->
            </v-carousel>
        </v-col>
        <!-- Image Carousel -->

        <!-- Bullet Text Items -->
        <v-col>
            <v-row
                style="background-color: #FFA858;"
                class="pb-16 pt-4"
                justify="center"
            >
                <v-col
                    cols="12"
                    :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
                >
                    <v-row>
                        <v-col
                            cols="12"
                            v-for="(item, index) in individualItems"
                            :key="index"
                            class="pa-0"
                        >
                            <v-list-item
                                class="pa-0"
                            >
                                <!-- Check Image -->
                                <v-list-item-avatar
                                    size="18"
                                    class="pa-0"
                                >
                                    <v-img
                                        src="/landing/main/general/LandingMainGeneralCheck.png"
                                        contain
                                        style="width: 18px;"
                                    />
                                </v-list-item-avatar>
                                <!-- Check Image -->

                                <!-- Heading -->
                                <v-list-item-content
                                    class="pa-0 rob-18-b"
                                    style="color: white;"
                                >
                                    <span>{{ item.heading }}</span>
                                </v-list-item-content>
                                <!-- Heading -->
                            </v-list-item>

                            <v-list-item
                                class="mt-n1 pa-0"
                            >
                                <!-- Spacer -->
                                <v-list-item-avatar
                                    size="18"
                                    class="pa-0"
                                >
                                </v-list-item-avatar>
                                <!-- Spacer -->

                                <!-- Text -->
                                <v-list-item-content
                                    class="pa-0 rob-14-r"
                                    style="color: white;"
                                >
                                    {{ item.text }}
                                </v-list-item-content>
                                <!-- Text -->
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- Signup Button -->
            <v-row
                align="center"
                justify="center"
            >
                <v-col
                    class="pa-0 text-center"
                    style="margin-top: -29px;"
                >
                    <v-btn
                        class="white--text no-uppercase rounded-lg rob-18-m"
                        color="#2E567A"
                        @click="showSignupOverlay"
                        style="height: 58px; width: 177px;"
                    >
                        Free Sign Up
                    </v-btn>
                </v-col>
            </v-row>
            <!-- Signup Button -->
        </v-col>
        <!-- Bullet Text Items -->
    </v-row>
    <!-- Mobile -->

    <!-- Desktop -->
    <v-row
        align="center"
        justify="center"
        style="position: relative;"
        v-else-if="$vuetify.breakpoint.mdAndUp"
        class="white--text"
    >
        <!-- Text -->
        <v-col
            cols="12"
            style="z-index: 1; position: absolute;"
            class="pa-4"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1110px;' : 'max-width: 920px;'"
        >
            <!-- For Companies -->
            <div
                :class="$vuetify.breakpoint.lgAndUp ? 'rob-18-r' : 'rob-16-r'"
                style="position: relative;"
            >
                For Companies
                <div
                    style="position: absolute; z-index: 2; top: 0; left: calc(var(--marginSize) * -1); background-color: #FFA858; height: 170px;"
                    :style="$vuetify.breakpoint.lgAndUp ? 'width: 41px;' : 'width: 25px;'"
                />
            </div>
            <!-- For Companies -->

            <!-- Heading -->
            <div
                class="py-3 py-lg-10"
                :class="$vuetify.breakpoint.lgAndUp ? 'mont-50-eb' : 'mont-45-eb'"
            >
                Recruit Talent <br />
                from anywhere
            </div>
            <!-- Heading -->

            <!-- Text Items -->
            <v-row>
                <v-col
                    cols="12"
                    v-for="(item, index) in individualItems"
                    :key="index"
                >
                    <div
                        :class="$vuetify.breakpoint.lgAndUp ? 'rob-18-b' : 'rob-16-b'"
                    >
                        {{ item.heading }}
                    </div>
                    <div
                        :class="$vuetify.breakpoint.lgAndUp ? 'rob-14-r' : 'rob-12-r'"
                        style="max-width: 285px;"
                    >
                        {{ item.text }}
                    </div>
                </v-col>
            </v-row>
            <!-- Text Items -->
        </v-col>
        <!-- Text -->

        <!-- Image Carousel -->
        <v-col
            cols="12"
            class="pa-0"
        >
            <v-carousel
                hide-delimiters
                :show-arrows="false"
                height="auto"
                cycle
                interval="10000"
            >
                <v-carousel-item
                    v-for="(item, index) in backgroundImages"
                    :key="index"
                    eager
                >
                    <v-img
                        :src="item.desktop"
                        width="100vw"
                        height="100%"
                        eager
                    >
                    </v-img>
                </v-carousel-item>
            </v-carousel>
        </v-col>
        <!-- Image Carousel -->

        <!-- Signup Button -->
        <v-btn
            class="white--text no-uppercase rounded-lg rob-18-m"
            color="#FFA858"
            style="width: 177px; height: 58px; z-index: 5; position: absolute; bottom: -29px; left: var(--marginSize);"
            @click="showSignupOverlay"
        >
            Free Sign Up
        </v-btn>
        <!-- Signup Button -->
    </v-row>
    <!-- Desktop -->
</template>
<script>
    export default {
        name: 'LandingCompanyComponent',

        data () {
            return {
                backgroundImages: [
                    {
                        desktop: '/landing/main/company/desktop/LandingMainCompanyDesktopBackground_1.webp',
                        mobile: '/landing/main/company/mobile/LandingMainCompanyMobileBackground_1.webp'
                    }
                ],
                individualItems: [
                    {
                        heading: 'Access Top Talent',
                        text: 'Access qualified talent from across the globe. Check CV’s and Post Jobs online.'
                    },
                    {
                        heading: 'Post Jobs & Positions',
                        text: 'Easily post vacancies and match the best talent to your requirements. All applications are handled within Fixer.'
                    },
                    // {
                    //     heading: 'Access Tenders',
                    //     text: 'Never miss another tender opportunity with comprehensive private and government tenders available in the tender portal.'
                    // },
                    {
                        heading: 'Hiring Support',
                        text: 'Get access to webinars, industry experts and Human Resource representatives for top advice.'
                    }
                ]
            }
        },

        methods: {
            showSignupOverlay () {
                this.$store.commit('setSignupOverlay', true)
            }
        }
    }
</script>

<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-14 15:38:05
 * @ Description: Component displaying the Featured section on the landing page.
 -->

<template>
    <!-- Mobile -->
    <v-row
        align="center"
        justify="center"
        style="height: 100%; color: #2E567A;"
        v-if="$vuetify.breakpoint.smAndDown"
    >
        <v-col
            cols="12"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
        >
            <v-row>
                <!-- Heading -->
                <v-col
                    class="mont-40-eb"
                    cols="12"
                >
                    Featured <br />
                    Talent
                </v-col>
                <!-- Heading -->

                <!-- Cards -->
                <v-col
                    cols="12"
                    class="px-0"
                >
                    <v-carousel
                        :show-arrows="false"
                        hide-delimiter-background
                        light
                        height="auto"
                        cycle
                        interval="5000"
                        class="pb-8"
                    >
                        <v-carousel-item
                            v-for="(item, index) in carouselData"
                            :key="index"
                            eager
                        >
                            <v-card
                                height="445px"
                                width="265px"
                                class="pa-5 mx-auto"
                                elevation="0"
                            >
                                <v-row
                                    style="height: 105%;"
                                    class="elevation-2"
                                    align="center"
                                >
                                    <!-- Text -->
                                    <v-col
                                        cols="12"
                                        class="pt-5 rob-14-r"
                                        style="color: #2E567A; height: 40%;"
                                    >
                                        {{ item.text }}
                                    </v-col>
                                    <!-- Text -->

                                    <!-- Image -->
                                    <v-col
                                        cols="12"
                                        class="pa-0 pb-2"
                                    >
                                        <v-img
                                            :src="item.image"
                                            height="90"
                                            contain
                                        />
                                    </v-col>
                                    <!-- Image -->

                                    <!-- Talent's Details -->
                                    <v-col
                                        cols="12"
                                        class="pa-0 text-center rob-14-r"
                                        style="color: #2E567A;"
                                    >
                                        {{ item.name }} <br />
                                        {{ item.title }}
                                    </v-col>
                                    <!-- Talent's Details -->

                                    <!-- Social Media Links -->
                                    <!-- <v-col
                                        cols="12"
                                        class="text-center"
                                    >
                                        <v-btn
                                            icon
                                            class="mr-2"
                                            aria-label="facebook"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                            >
                                                {{ mdiFacebook }}
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            icon
                                            class="mr-2"
                                            aria-label="twitter"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                            >
                                                {{ mdiTwitter }}
                                            </v-icon>
                                        </v-btn>
                                    </v-col> -->
                                    <!-- Social Media Links -->
                                </v-row>
                            </v-card>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <!-- Cards -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Mobile -->

    <!-- Desktop -->
    <v-row
        align="center"
        justify="center"
        style="color: #2E567A;"
        v-else-if="$vuetify.breakpoint.mdAndUp"
    >
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1110px;' : 'max-width: 920px;'"
        >
            <v-row
                class="pa-2 pa-lg-4"
            >
                <!-- Heading -->
                <v-col
                    class="text-center pb-6"
                    :class="$vuetify.breakpoint.lgAndUp ? 'mont-50-eb' : 'mont-45-eb'"
                    cols="12"
                >
                    Featured Talent
                </v-col>
                <!-- Heading -->

                <!-- Cards Carousel -->
                <v-col
                    cols="4"
                    v-for="(item, index) in carouselData"
                    :key="index"
                >
                    <v-card
                        :width="$vuetify.breakpoint.lgAndUp ? '350' : '280'"
                        height="422"
                        max-height="422"
                        :max-width="$vuetify.breakpoint.lgAndUp ? '350' : '280'"
                        elevation="0"
                        class="mx-auto"
                    >
                        <v-row
                            style="height: 110%; width: 100%;"
                            class="elevation-2 mx-auto pa-4"
                        >
                            <!-- Text -->
                            <v-col
                                cols="12"
                                :class="$vuetify.breakpoint.lgAndUp ? 'rob-18-r' : 'rob-16-r'"
                                style="color: #2E567A; height: 57%;"
                            >
                                {{ item.text }}
                            </v-col>
                            <!-- Text -->

                            <!-- Image -->
                            <v-col
                                cols="6"
                                align-self="center"
                            >
                                <v-img
                                    :src="item.image"
                                    height="90"
                                    contain
                                />
                            </v-col>
                            <!-- Image -->

                            <!-- Talent's Details -->
                            <v-col
                                cols="7"
                                style="color: #2E567A;"
                                offset="1"
                                class="px-0"
                            >
                                <span :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-r' : 'rob-14-r'">{{ item.name }}</span> <br />
                                <span :class="$vuetify.breakpoint.lgAndUp ? 'rob-14-r' : 'rob-12-r'">{{ item.title }}</span>
                            </v-col>
                            <!-- Talent's Details -->

                            <!-- Social Media Links -->
                            <!-- <v-col
                                cols="4"
                                class="text-right px-0"
                                align-self="end"
                            >
                                <v-btn
                                    icon
                                    aria-label="facebook"
                                >
                                    <v-icon
                                        color="#2E567A"
                                    >
                                        {{ mdiFacebook }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    aria-label="twitter"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        size="24"
                                    >
                                        {{ mdiTwitter }}
                                    </v-icon>
                                </v-btn>
                            </v-col> -->
                            <!-- Social Media Links -->
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Cards Carousel -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Desktop -->
</template>
<script>
    import { mdiFacebook, mdiTwitter } from '@mdi/js'

    export default {
        name: 'LandingFeaturedComponent',

        data () {
            return {
                mdiFacebook,
                mdiTwitter,
                carouselData: [
                    {
                        text: '“Fixer has made it super easy for me to list my services on their website. My favourite part is that all communication happens on the app and that I am paid directly through Fixer once the job has been completed. I’m happy with the service.”',
                        name: 'Steve',
                        title: 'Handyman',
                        image: '/landing/main/featured/LandingMainFeaturedCarousel_1.png'
                    },
                    {
                        text: '“Fixer has given me the freedom to turn my hobby into a service. The website is easy to navigate and use and I love that you can list your services for free. I also love that it is not an expensive marketing platform and that I receive money from Fixer once the job has been completed.“',
                        name: 'Thandi',
                        title: 'Graphic Designer',
                        image: '/landing/main/featured/LandingMainFeaturedCarousel_2.png'
                    },
                    {
                        text: '“I love using Fixer to list my services. I’ve been using the platform for a month now and it has really boosted my business. The small monthly fee is totally worth it, because of the guaranteed pay out from Fixer. I will continue to use the platform in the future.”',
                        name: 'Dave',
                        title: 'Electrician',
                        image: '/landing/main/featured/LandingMainFeaturedCarousel_3.png'
                    }
                ]
            }
        }
    }
</script>

<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-08-10 15:36:38
 * @ Description: Component displaying the Footer section on the landing page.
 -->

<template>
    <!-- Mobile -->
    <v-row
        align="center"
        justify="center"
        style="height: 100%; color: #2E567A;"
        v-if="$vuetify.breakpoint.smAndDown"
    >
        <v-col
            cols="12"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
            class="pb-9"
        >
            <v-row>
                <!-- Text -->
                <v-col
                    class="text-center mont-115-eb"
                    cols="12"
                >
                    Join our newsletter and become part of the Fixer community to get notified about our latest news and updates.
                </v-col>
                <!-- Text -->

                <!-- E-mail Input -->
                <v-col
                    class="pl-4 pr-4 pl-sm-16 pr-sm-16"
                    cols="12"
                >
                    <v-form
                        ref="subscribeForm"
                        v-model="validSubscribeForm"
                    >
                        <v-text-field
                            dense
                            outlined
                            color="#2E567A"
                            class="os-13-r subscribe-mobile"
                            background-color="white"
                            v-model="email"
                            placeholder='E-mail address...'
                            style="width: 100%;"
                            :rules="emailRules"
                            :hide-details="validSubscribeForm"
                        />
                    </v-form>
                </v-col>
                <!-- E-mail Input -->

                <!-- Subscribe Button -->
                <v-col
                    cols="12"
                    class="text-center"
                >
                    <v-btn
                        class="white--text no-uppercase rounded-lg rob-18-m"
                        color="#FFA858"
                        style="height: 58px; width: 177px;"
                        @click="subscribe"
                        :disabled="!validSubscribeForm || !email"
                    >
                        Subscribe
                    </v-btn>
                </v-col>
                <!-- Subscribe Button -->

                <v-col
                    cols="12"
                    class="text-center"
                >
                    <!-- Support -->
                    <div
                        class="pt-4 rob-16-r"
                        style="font-weight: bold !important;"
                    >
                        Support
                    </div>
                    <div
                        class="rob-14-r"
                        @click="goToMenuItem('landing-pricing')"
                    >
                        Pricing Plan
                    </div>
                    <!-- Support -->

                    <!-- Company -->
                    <div
                        class="pt-4 rob-16-r"
                        style="font-weight: bold !important;"
                    >
                        Company
                    </div>
                    <div
                        class="rob-14-r"
                        @click="showSignupOverlay"
                    >
                        Join Us
                    </div>
                    <!-- Company -->

                    <!-- Legal -->
                    <div
                        class="pt-4 rob-16-r"
                        style="font-weight: bold !important;"
                    >
                        Legal
                    </div>
                    <div
                        class="rob-14-r"
                        @click="$router.push('/privacy')"
                    >
                        Privacy
                    </div>
                    <div
                        class="rob-14-r"
                        @click="$router.push('/terms')"
                    >
                        Terms
                    </div>
                    <!-- Legal -->
                </v-col>

                <!-- Logo -->
                <v-col
                    cols="12"
                >
                    <v-img
                        src="/branding/FixerLogoBlue.png"
                        width="180px"
                        class="mx-auto"
                    />
                </v-col>
                <!-- Logo -->

                <!-- Social Media Links -->
                <v-col
                    cols="12"
                    class="text-center"
                >
                    <!-- Facebook -->
                    <v-btn
                        icon
                        @click="openSocialMediaLink('https://www.facebook.com/9to5Fixer')"
                        aria-label="facebook"
                        small
                    >
                        <v-icon
                            color="#2E567A"
                            small
                        >
                            {{ mdiFacebook }}
                        </v-icon>
                    </v-btn>
                    <!-- Facebook -->

                    <!-- LinkedIn -->
                    <v-btn
                        icon
                        @click="openSocialMediaLink('https://www.linkedin.com/company/9to5fixer/mycompany/')"
                        aria-label="linkedin"
                        small
                    >
                        <v-icon
                            color="#2E567A"
                            small
                        >
                            {{ mdiLinkedin }}
                        </v-icon>
                    </v-btn>
                    <!-- LinkedIn -->

                    <!-- Twitter -->
                    <v-btn
                        icon
                        @click="openSocialMediaLink('https://twitter.com/9to5Fixer')"
                        aria-label="twitter"
                        small
                    >
                        <v-icon
                            color="#2E567A"
                            small
                        >
                            {{ mdiTwitter }}
                        </v-icon>
                    </v-btn>
                    <!-- Twitter -->

                    <!-- Instagram -->
                    <v-btn
                        icon
                        @click="openSocialMediaLink('https://www.instagram.com/9to5fixer/')"
                        aria-label="instagram"
                        small
                    >
                        <v-icon
                            color="#2E567A"
                            small
                        >
                            {{ mdiInstagram }}
                        </v-icon>
                    </v-btn>
                    <!-- Instagram -->
                </v-col>
                <!-- Social Media Links -->

                <!-- Bottom Text -->
                <v-col
                    cols="12"
                    class="text-center text-caption text-sm-subtitle-1 pb-12 pb-sm-16"
                    style="color: rgba(47, 86, 122, 0.4);"
                >
                    &copy; Fixer {{ new Date().getFullYear() }}. All right reserved
                </v-col>
                <!-- Bottom Text -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Mobile -->

    <!-- Desktop -->
    <v-row
        style="height: 100%; color: #2E567A;"
        v-else-if="$vuetify.breakpoint.mdAndUp"
        justify="center"
    >
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1110px;' : 'max-width: 920px;'"
        >
            <v-row>
                <v-col
                    cols="2"
                >
                    <!-- Logo -->
                    <v-img
                        src="/branding/FixerLogoBlue.png"
                        width="97px"
                        class="mb-6"
                    />
                    <!-- Logo -->

                    <!-- Address -->
                    <div
                        style="color: rgba(47, 86, 122, 0.4);"
                        :class="$vuetify.breakpoint.lgAndUp ? 'rob-14-r' : 'rob-12-r'"
                    >
                        Level 3 <br />
                        Alexander House <br />
                        35 Cybercity <br />
                        Ebene <br />
                        72201 <br />
                        Mauritius <br />
                        support@9to5fixer.com <br />
                        +27 12 944 2020
                    </div>
                    <!-- Address -->
                </v-col>

                <!-- Support -->
                <v-col
                    :class="$vuetify.breakpoint.lgAndUp ? 'rob-14-r' : 'rob-12-r'"
                    cols="2"
                >
                    <div
                        class="rob-16-r pb-6"
                    >
                        Support
                    </div>
                    <div
                        style="cursor: pointer;"
                        @click="goToMenuItem('landing-pricing')"
                    >
                        Pricing Plan
                    </div>
                </v-col>
                <!-- Support -->

                <!-- Company -->
                <v-col
                    :class="$vuetify.breakpoint.lgAndUp ? 'rob-14-r' : 'rob-12-r'"
                    cols="2"
                >
                    <div
                        class="pb-6"
                        :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-r' : 'rob-14-r'"
                    >
                        Company
                    </div>
                    <div
                        style="cursor: pointer;"
                        @click="showSignupOverlay"
                    >
                        Join Us
                    </div>
                </v-col>
                <!-- Company -->

                <!-- Legal -->
                <v-col
                    :class="$vuetify.breakpoint.lgAndUp ? 'rob-14-r' : 'rob-12-r'"
                    cols="2"
                >
                    <div
                        class="pb-6"
                        :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-r' : 'rob-14-r'"
                    >
                        Legal
                    </div>
                    <div
                        style="cursor: pointer;"
                        @click="$router.push('/privacy')"
                    >
                        Privacy
                    </div>
                    <div
                        style="cursor: pointer;"
                        @click="$router.push('/terms')"
                    >
                        Terms
                    </div>
                </v-col>
                <!-- Legal -->

                <!-- Newsletter -->
                <v-col
                    :class="$vuetify.breakpoint.lgAndUp ? 'rob-14-r' : 'rob-12-r'"
                    cols="4"
                >
                    <v-form
                        ref="subscribeForm"
                        v-model="validSubscribeForm"
                    >
                        <!-- Text -->
                        <div
                            class="pb-6"
                            :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-r' : 'rob-14-r'"
                        >
                            Newsletter
                        </div>
                        <div>
                            Join our newsletter and become part of the Fixer community to get notified about our latest news and updates.
                        </div>
                        <!-- Text -->

                        <!-- E-mail Input and Subscribe Button -->
                        <div
                            style="display: flex; align-items: center;"
                            class="pt-8"
                        >
                            <v-text-field
                                dense
                                outlined
                                color="#2E567A"
                                class="os-13-r subscribe"
                                background-color="white"
                                v-model="email"
                                placeholder='E-mail address...'
                                :style="$vuetify.breakpoint.lgAndUp ? 'width: 394px;' : 'width: 360px;'"
                                :rules="emailRules"
                            />
                            <v-btn
                                class="white--text no-uppercase rounded-r-lg rob-18-m"
                                :class="!validSubscribeForm || !email ? '' : 'ml-n1'"
                                color="#FFA858"
                                style="height: 58px; margin-top: -26px;"
                                :style="$vuetify.breakpoint.lgAndUp ? 'width: 128px;' : 'width: 100px;'"
                                @click="subscribe"
                                :disabled="!validSubscribeForm || !email"
                            >
                                Subscribe
                            </v-btn>
                        </div>
                        <!-- E-mail Input and Subscribe Button -->
                    </v-form>
                </v-col>
                <!-- Newsletter -->

                <v-col
                    cols="12"
                    class="pt-16 pb-8"
                    :class="$vuetify.breakpoint.lgAndUp ? 'rob-14-r' : 'rob-12-r'"
                >
                    <v-row>
                        <!-- Left Bottom Text -->
                        <v-col
                            cols="8"
                        >
                            <span>&copy; Fixer {{ new Date().getFullYear() }}. All right reserved</span>
                            <span @click="$router.push('/privacy')" class="ml-12" style="color: rgba(47, 86, 122, 0.4); cursor: pointer;">Privacy Policy</span>
                            <span @click="$router.push('/terms')" class="ml-12" style="color: rgba(47, 86, 122, 0.4); cursor: pointer;">Terms of Service</span>
                        </v-col>
                        <!-- Left Bottom Text -->

                        <!-- Social Media Links -->
                        <v-col
                            cols="4"
                            class="text-right"
                        >
                            <!-- Facebook -->
                            <v-btn
                                icon
                                @click="openSocialMediaLink('https://www.facebook.com/9to5Fixer')"
                                aria-label="facebook"
                                small
                            >
                                <v-icon
                                    color="#2E567A"
                                    small
                                >
                                    {{ mdiFacebook }}
                                </v-icon>
                            </v-btn>
                            <!-- Facebook -->

                            <!-- LinkedIn -->
                            <v-btn
                                icon
                                @click="openSocialMediaLink('https://www.linkedin.com/company/9to5fixer/mycompany/')"
                                aria-label="linkedin"
                                small
                            >
                                <v-icon
                                    color="#2E567A"
                                    small
                                >
                                    {{ mdiLinkedin }}
                                </v-icon>
                            </v-btn>
                            <!-- LinkedIn -->

                            <!-- Twitter -->
                            <v-btn
                                icon
                                @click="openSocialMediaLink('https://twitter.com/9to5Fixer')"
                                aria-label="twitter"
                                small
                            >
                                <v-icon
                                    color="#2E567A"
                                    small
                                >
                                    {{ mdiTwitter }}
                                </v-icon>
                            </v-btn>
                            <!-- Twitter -->

                            <!-- Instagram -->
                            <v-btn
                                icon
                                @click="openSocialMediaLink('https://www.instagram.com/9to5fixer/')"
                                aria-label="instagram"
                                small
                            >
                                <v-icon
                                    color="#2E567A"
                                    small
                                >
                                    {{ mdiInstagram }}
                                </v-icon>
                            </v-btn>
                            <!-- Instagram -->
                        </v-col>
                        <!-- Social Media Links -->
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <!-- Desktop -->
</template>
<script>
    import { mdiFacebook, mdiTwitter, mdiInstagram, mdiLinkedin } from '@mdi/js'
    import { NewsletterController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'LandingFooterComponent',

        computed: {
            emailRules () {
                if (this.email === null || this.email === '') return []
                else if (this.email.replace(/\s/g, '') === '') return [ 'Invalid e-mail address' ]
                else return [ v => /\S+@\S+\.\S+/.test(v) || 'Invalid e-mail address' ]
            }
        },

        data () {
            return {
                mdiFacebook,
                mdiTwitter,
                mdiInstagram,
                mdiLinkedin,
                newsletterController: null,
                email: '',
                validSubscribeForm: false
            }
        },

        beforeMount () {
            this.newsletterController = new NewsletterController()
        },

        methods: {
            goToMenuItem (link) {
                const root = document.getElementById(link)
                root.scrollIntoView({ behavior: 'smooth' })
            },

            openSocialMediaLink (link) {
                window.open(link, '_blank')
            },

            showSignupOverlay () {
                this.$store.commit('setSignupOverlay', true)
            },

            async subscribe () {
                try {
                    await this.newsletterController.subscribe(this.email)

                    this.$store.commit('setSnackbar', {
                        text: 'Subscribed successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.email = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style scoped>
    input:focus {
        outline: none;
    }
</style>

<style>
    .subscribe div.v-input__slot.white {
        min-height: 0 !important;
        height: 58px !important;
        margin-top: 1px;
    }

    .subscribe-mobile div.v-input__slot.white {
        height: 48px !important;
        margin-top: 1px;
    }
</style>
<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-03-28 12:40:04
 * @ Description: Component displaying the Pricing section on the landing page.
 -->

<template>
    <!-- Mobile -->
    <v-row
        align="center"
        justify="center"
        style="height: 100%; color: #2E567A;"
        v-if="$vuetify.breakpoint.smAndDown"
        id="landing-pricing"
    >
        <v-col
            cols="12"
            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
        >
            <v-row>
                <!-- Heading -->
                <v-col
                    class="text-center mont-40-eb"
                    cols="12"
                >
                    Pricing
                </v-col>
                <!-- Heading -->

                <!-- Radio Group -->
                <v-col
                    cols="12"
                    style="display: flex; justify-content: center; align-items: center;"
                    class="text-h6"
                >
                    <v-radio-group
                        row
                        v-model="subscriptionTypeRadio"
                        hide-details
                        style="margin-top: 0px;"
                        @change="subscriptionTypeChanged"
                    >
                        <v-radio
                            label="Individual"
                            color="#2E567A"
                            class="blue--text--xxs"
                            :ripple="false"
                            value="Individual"
                        />
                        <v-radio
                            label="Company"
                            color="#2E567A"
                            class="blue--text--xxs"
                            :ripple="false"
                            value="Company"
                        />
                    </v-radio-group>
                </v-col>
                <!-- Radio Group -->

                <!-- Cards Carousel -->
                <v-col
                    cols="12"
                    class="px-0"
                >
                    <v-carousel
                        :show-arrows="false"
                        hide-delimiter-background
                        light
                        height="auto"
                        cycle
                        interval="5000"
                        class="pb-16"
                        v-model="mobileCarousel"
                        @change="findFittextFontSize"
                    >
                        <v-carousel-item
                            v-for="(item, index) in carouselData"
                            :key="index"
                            eager
                        >
                            <v-card
                                width="265px"
                                max-width="265px"
                                class="pa-4 mx-auto"
                                elevation="0"
                            >
                                <v-row
                                    style="height: 100%;"
                                    class="elevation-2"
                                >
                                    <!-- Package Name -->
                                    <v-col
                                        cols="12"
                                        class="text-center align-self-center pa-6 mont-25-eb"
                                        style="background: rgba(243, 170, 99, 0.11); color: #2E567A;"
                                    >
                                        {{ item.name }}
                                    </v-col>
                                    <!-- Package Name -->

                                    <!-- Top Text -->
                                    <v-col
                                        cols="12"
                                        class="text-center pl-2 pr-2 rob-16-r"
                                        style="color: #2E567A; min-height: 72px !important;"
                                        v-html="item.description.topText"
                                    />
                                    <!-- Top Text -->

                                    <!-- Price -->
                                    <v-col
                                        cols="12"
                                        class="py-0 text-center"
                                        style="display: flex; align-items: center; justify-content: center; color: #F3AA63; height: 60px; overflow-y: auto; font-size: 40px; font-family: 'MontserratExtraBold' !important;"
                                        :id="'fittext-' + index"
                                    >
                                        {{ item.description.price }}
                                    </v-col>
                                    <!-- Price -->

                                    <!-- Bottom Text -->
                                    <v-col
                                        cols="12"
                                        class="text-center pl-4 pr-4 rob-16-r"
                                        style="color: #2E567A;"
                                    >
                                        {{ item.description.bottomText }}
                                    </v-col>
                                    <!-- Bottom Text -->

                                    <!-- Get Started Button -->
                                    <v-col
                                        class="text-center mt-n5 pb-6 pt-6"
                                    >
                                        <v-btn
                                            class="white--text no-uppercase rounded-lg rob-18-m"
                                            style="width: 191px; height: 48px;"
                                            color="#2E567A"
                                            @click="showSignupOverlay"
                                        >
                                            Get Started
                                        </v-btn>
                                    </v-col>
                                    <!-- Get Started Button -->
                                </v-row>
                            </v-card>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <!-- Cards Carousel -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Mobile -->

    <!-- Desktop -->
    <v-row
        align="center"
        justify="center"
        style="color: #2E567A;"
        v-else-if="$vuetify.breakpoint.mdAndUp"
        id="landing-pricing"
    >
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1110px;' : 'max-width: 920px;'"
        >
            <v-row
                class="pa-4"
                justify="center"
                align="center"
            >
                <!-- Heading -->
                <v-col
                    class="text-center"
                    :class="$vuetify.breakpoint.lgAndUp ? 'mont-60-eb' : 'mont-51-eb'"
                    cols="12"
                >
                    Pricing
                </v-col>
                <!-- Heading -->

                <!-- Radio Group -->
                <v-col
                    cols="12"
                    style="display: flex; justify-content: center; align-items: center;"
                    class="text-h6 pb-12"
                >
                    <v-radio-group
                        row
                        v-model="subscriptionTypeRadio"
                        hide-details
                        @change="subscriptionTypeChanged"
                    >
                        <v-radio
                            label="Individual"
                            color="#2E567A"
                            :class="$vuetify.breakpoint.lgAndUp ? 'blue--text--lg' : 'blue--text--md'"
                            :ripple="false"
                            value="Individual"
                        />
                        <v-radio
                            label="Company"
                            color="#2E567A"
                            :class="$vuetify.breakpoint.lgAndUp ? 'blue--text--lg' : 'blue--text--md'"
                            :ripple="false"
                            value="Company"
                        />
                    </v-radio-group>
                </v-col>
                <!-- Radio Group -->

                <!-- Cards -->
                <v-col
                    cols="4"
                    v-for="(item, index) in carouselData"
                    :key="index"
                >
                    <v-card
                        class="mx-auto text-center"
                        elevation="0"
                        height="374"
                        max-height="374"
                        :width="$vuetify.breakpoint.lgAndUp ? '255' : '210'"
                        :max-width="$vuetify.breakpoint.lgAndUp ? '255' : '210'"
                    >
                        <v-row
                            style="width: 100%;"
                            class="elevation-1 mx-auto"
                        >
                            <!-- Package Name -->
                            <v-col
                                cols="12"
                                :class="$vuetify.breakpoint.lgAndUp ? 'mont-25-eb' : 'mont-20-eb'"
                                style="background: rgba(243, 170, 99, 0.11); color: #2E567A; height: 86px; display: flex; align-items: center; justify-content: center;"
                            >
                                {{ item.name }}
                            </v-col>
                            <!-- Package Name -->

                            <!-- Top Text -->
                            <v-col
                                cols="12"
                                class="text-center pl-2 pr-2"
                                :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-r' : 'rob-14-r'"
                                style="color: #2E567A; min-height: 72px !important;"
                                v-html="item.description.topText"
                            />
                            <!-- Top Text -->

                            <!-- Price -->
                            <v-col
                                cols="12"
                                class="py-0 text-center"
                                style="display: flex; align-items: center; justify-content: center; color: #F3AA63; height: 60px; overflow-y: auto; font-size: 40px; font-family: 'MontserratExtraBold' !important;"
                                :id="'fittext-' + index"
                            >
                                {{ item.description.price }}
                            </v-col>
                            <!-- Price -->

                            <!-- Bottom Text -->
                            <v-col
                                cols="12"
                                class="text-center pl-2 pr-2"
                                :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-r' : 'rob-14-r'"
                                style="color: #2E567A;"
                            >
                                {{ item.description.bottomText }}
                            </v-col>
                            <!-- Bottom Text -->

                            <!-- Get Started Button -->
                            <v-col
                                class="text-center pb-6"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg rob-18-m"
                                    color="#2E567A"
                                    style="width: 191px; height: 48px;"
                                    :style="$vuetify.breakpoint.lgAndUp ? 'width: 191px;' : 'width: 185px;'"
                                    @click="showSignupOverlay"
                                >
                                    Get Started
                                </v-btn>
                            </v-col>
                            <!-- Get Started Button -->
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Cards -->
            </v-row>
        </v-col>
    </v-row>
    <!-- Desktop -->
</template>
<script>
    import { LandingSubscriptionController } from '@/logic/controller/index.controller.js' 

    export default {
        name: 'LandingPricingComponent',

        computed: {
            carouselData () {
                if (this.subscriptionTypeRadio === 'Company') return this.data.company
                else return this.data.individual
            },

            // removeTrialPackage () {
            //     return this.carouselData.filter(function (e) {
            //         return (e.id !== 1 && e.id !== 5)
            //     })
            // }
        },

        watch: {
            '$vuetify.breakpoint': {
                handler () {
                    this.$nextTick(() => {
                        this.$forceUpdate()
                    })
                },
                deep: true
            }
        },

        data () {
            return {
                subscriptionTypeRadio: 'Individual',
                mobileCarousel: 0,
                data: {
                    individual: [],
                    company: []
                },
                landingSubscriptionController: null
            }
        },

        beforeMount () {
            this.landingSubscriptionController = new LandingSubscriptionController()
        },

        async mounted () {
            await this.getSubscriptionData()
        },

        updated () {
            this.$nextTick(() => {
                for (let index = 0; index < this.carouselData.length; index++) {
                    var fittext = document.getElementById(`fittext-${index}`)
                    this.findFittextFontSize(fittext)
                }
            })
        },

        methods: {
            async getSubscriptionData () {
                try {
                    this.data = await this.landingSubscriptionController.get()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showSignupOverlay () {
                this.$store.commit('setSignupOverlay', true)
            },

            subscriptionTypeChanged () {
                this.$emit('subscriptionTypeChanged', this.subscriptionTypeRadio)
            },

            findFittextFontSize (fittext) {
                this.$nextTick(() => {
                    if (fittext.scrollHeight > fittext.clientHeight) {
                        this.$nextTick(() => {
                            var fontSize = fittext.style.fontSize
                            var fontSizeNumber = fontSize.split('px')[0]
                            fittext.style.fontSize = `${Number(fontSizeNumber - 1)}px`

                            this.findFittextFontSize(fittext)
                        })
                    } else if (fittext.scrollWidth > fittext.clientWidth) {
                        this.$nextTick(() => {
                            var fontSize = fittext.style.fontSize
                            var fontSizeNumber = fontSize.split('px')[0]
                            fittext.style.fontSize = `${Number(fontSizeNumber - 1)}px`

                            this.findFittextFontSize(fittext)
                        })
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .blue--text--md /deep/ label {
        color: #2E567A;
        font-size: 25px;
        font-weight: bold;
        font-family: 'MontserratExtraBold' !important;
    }

    .blue--text--lg /deep/ label {
        color: #2E567A;
        font-size: 30px;
        font-weight: bold;
        font-family: 'MontserratExtraBold' !important;
    }

    .blue--text--xxs /deep/ label {
        color: #2E567A;
        font-size: 14px;
        font-weight: bold;
        font-family: 'MontserratExtraBold' !important;
    }
</style>
<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-08-10 17:01:38
 * @ Description: Fixer's main page in landing section of the app.
 -->

<template>
    <!-- Mobile -->
    <div
        v-if="$vuetify.breakpoint.smAndDown"
    >
        <!-- Main -->
        <landing-main-component
            class="pb-8"
        />
        <!-- Main -->

        <!-- What Is Fixer -->
        <landing-what-is-fixer-component />
        <!-- What Is Fixer -->

        <!-- Individual -->
        <landing-individual-component />
        <!-- Individual -->

        <!-- Featured -->
        <landing-featured-component />
        <!-- Featured -->

        <!-- Company -->
        <landing-company-component />
        <!-- Company -->

        <!-- How It Works -->
        <landing-how-it-works-component />
        <!-- How It Works -->

        <!-- Pricing -->
        <landing-pricing-component
            @subscriptionTypeChanged="subscriptionTypeChanged"
        />
        <!-- Pricing -->

        <!-- Testimonials -->
        <landing-testimonials-component />
        <!-- Testimonials -->

        <!-- Included -->
        <landing-included-component
            :subscriptionType="subscriptionType"
        />
        <!-- Included -->

        <!-- Footer -->
        <landing-footer-component />
        <!-- Footer -->
    </div>
    <!-- Mobile -->

    <!-- Desktop -->
    <div
        v-else-if="$vuetify.breakpoint.mdAndUp"
    >
        <v-app-bar
            app
            height="50"
            style="font-size: 28px; font-weight: 400; color: #FFA858;"
            elevation="0"
            color="rgb(252, 241, 230)"
        >
            <div
                style="display: flex; align-items: center; justify-content: center; width: 100vw;"
            >
                DOWNLOAD OUR&nbsp;<span style="font-weight: 700; text-decoration: underline;">APP</span>&nbsp;NOW!
                <a href="https://play.google.com/store/apps/details?id=coza.fixer.fixer" style="display: flex; align-items: center; justify-content: center;">
                    <img
                        src="/landing/GooglePlay.png"
                        height="50"
                        style="cursor: pointer;"
                    />
                </a>
            </div>
        </v-app-bar>

        <!-- Main -->
        <landing-main-component />
        <!-- Main -->

        <!-- What Is Fixer -->
        <landing-what-is-fixer-component
            class="pb-12"
        />
        <!-- What Is Fixer -->

        <!-- Company -->
        <landing-company-component/>
        <!-- Company -->

        <!-- Featured -->
        <landing-featured-component
            class="py-12"
        />
        <!-- Featured -->

        <!-- Individual -->
        <landing-individual-component />
        <!-- Individual -->

        <!-- How It Works -->
        <landing-how-it-works-component
            class="py-12"
        />
        <!-- How It Works -->

        <!-- Pricing -->
        <landing-pricing-component
            @subscriptionTypeChanged="subscriptionTypeChanged"
            class="py-12"
        />
        <!-- Pricing -->

        <!-- Testimonials -->
        <landing-testimonials-component />
        <!-- Testimonials -->

        <!-- Included -->
        <landing-included-component
            :subscriptionType="subscriptionType"
            class="py-12"
        />
        <!-- Included -->

        <!-- Footer -->
        <landing-footer-component
            class="py-12"
        />
        <!-- Footer -->
    </div>
    <!-- Desktop -->
</template>
<script>
    import LandingMainComponent from '@/components/landing/main/LandingMainComponent.vue'
    import LandingWhatIsFixerComponent from '@/components/landing/main/LandingWhatIsFixerComponent.vue'
    import LandingIndividualComponent from '@/components/landing/main/LandingIndividualComponent.vue'
    import LandingCompanyComponent from '@/components/landing/main/LandingCompanyComponent.vue'
    import LandingFeaturedComponent from '@/components/landing/main/LandingFeaturedComponent.vue'
    import LandingHowItWorksComponent from '@/components/landing/main/LandingHowItWorksComponent.vue'
    import LandingPricingComponent from '@/components/landing/main/LandingPricingComponent.vue'
    import LandingTestimonialsComponent from '@/components/landing/main/LandingTestimonialsComponent.vue'
    import LandingIncludedComponent from '@/components/landing/main/LandingIncludedComponent.vue'
    import LandingFooterComponent from '@/components/landing/main/LandingFooterComponent.vue'
    import AxiosHelper from '@/logic/helper/axios/axios.helper.js'
    import { CurrencyController, GeneralAuthController } from '@/logic/controller/index.controller.js'
    import store from '@/store/index.js'
    import router from '@/router/index.js'

    export default {
        name: 'MainPage',

        async beforeRouteEnter (to, from, next) {
            if (store.state.user.userData.token) {
                const generalAuthCntroller = new GeneralAuthController()
                try {
                    await generalAuthCntroller.authenticate()

                    if (store.state.general.androidNotificationTapped) next()
                    else router.push('/dashboard').catch(() => true)
                    
                    store.commit('setAndroidNotificationTapped', false)
                } catch (error) {
                    next()
                }
            } else next()
        },

        components: {
            LandingMainComponent,
            LandingWhatIsFixerComponent,
            LandingIndividualComponent,
            LandingCompanyComponent,
            LandingFeaturedComponent,
            LandingHowItWorksComponent,
            LandingPricingComponent,
            LandingTestimonialsComponent,
            LandingIncludedComponent,
            LandingFooterComponent
        },

        data () {
            return {
                subscriptionType: 'Individual',
                currencyController: null
            }
        },

        async beforeMount () {
            this.currencyController = new CurrencyController()

            this.$http = new AxiosHelper()
            this.$http.store = this.$store
            this.$http.router = this.$router
        },

        async mounted () {
            await this.getCurrencyRate()

            if (this.$store.state.overlay.overlays.general.expiredSession) this.$store.commit('setExpiredSessionOverlay', false)
        },

        methods: {
            subscriptionTypeChanged (subscriptionType) {
                this.subscriptionType = subscriptionType
            },

            async getCurrencyRate () {
                try {
                    const response = await this.currencyController.getCurrencyRate()
                    this.$store.commit('setCurrency', response[0])
                } catch (error) {}
            }
        }
    }
</script>
<style>
    @import url('../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
<style scoped>
    >>> .v-carousel__controls__item {
        color: #2E567A !important;
    }

    >>> .v-carousel__controls {
        background-color: rgba(47, 86, 122, 0/* .07 */) !important;
    }

    >>> .v-window__prev, >>> .v-window__next {
        background: rgba(0, 0, 0, 0);
    }
</style>

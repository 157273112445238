<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-14 15:47:58
 * @ Description: Component displaying the Testimonials section on the landing page.
 -->

<template>
    <!-- Mobile -->
    <v-row
        align="center"
        style="height: 100%; background-color: #F0F3F6;"
        v-if="$vuetify.breakpoint.smAndDown"
        class="white--text pb-4"
    >
        <v-col
            cols="12"
            class="pa-0"
        >
            <!-- Testimonial Carousel -->
            <v-carousel
                :show-arrows="false"
                height="auto"
                cycle
                interval="10000"
                class="pb-16"
            >
                <v-carousel-item
                    v-for="(item, index) in backgroundImagesMobile"
                    :key="index"
                    eager
                    style="z-index: 5; position: relative;"
                >
                    <!-- Image -->
                    <v-img
                        :src="item.mobile"
                        width="auto"
                        :height="$vuetify.breakpoint.smAndUp ? '1000px' : '550px'"
                        eager
                    >
                    </v-img>
                    <!-- Image -->

                    <!-- Text -->
                    <v-col
                        cols="12"
                        style="z-index: 1; position: absolute; bottom: 0; display: flex; justify-content: center;"
                        class="pa-8"
                    >
                        <v-row
                            style="height: 275px; background: rgba(47, 86, 122, 0.68); color: white; font-weight: 300;"
                            :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : 'max-width: 500px;' "
                            align="end"
                            class="pa-3"
                        >
                            <!-- Testimonial -->
                            <v-col
                                cols="12"
                                class="pa-0 text-sm-center"
                                :class="$store.state.general.customBreakpoints.xxs ? 'rob-14-r' : 'rob-18-r'"
                                v-html="item.text"
                                style="line-height: 1.25;"
                            />
                            <!-- Testimonial -->

                            <!-- Customer's Details -->
                            <v-col
                                cols="12"
                                class="text-center px-0 py-4 rob-125-r"
                                v-html="item.name"
                            />
                            <!-- Customer's Details -->
                        </v-row>
                    </v-col>
                    <!-- Text -->
                </v-carousel-item>
            </v-carousel>
            <!-- Testimonial Carousel -->
        </v-col>
    </v-row>
    <!-- Mobile -->

    <!-- Desktop -->
    <v-row
        align="center"
        justify="center"
        style="height: 100%; background-color: #F0F3F6;"
        v-else-if="$vuetify.breakpoint.mdAndUp"
        class="white--text pt-lg-16 pb-lg-16 pt-md-8 pb-md-8"
    >
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1110px;' : 'max-width: 920px;'"
        >
            <!-- Testimonial Carousel -->
            <v-carousel
                height="auto"
                cycle
                interval="10000"
                light
                :style="$vuetify.breakpoint.lgAndUp ? 'padding-bottom: 96px;' : 'padding-bottom: 64px;'"
            >
                <v-carousel-item
                    v-for="(item, index) in backgroundImages"
                    :key="index"
                    eager
                    class="mx-auto"
                >
                    <v-row
                        align="center"
                        justify="center"
                    >
                        <!-- Image -->
                        <v-col
                            cols="6"
                        >
                            <v-img
                                :src="item.desktop"
                                :width="$vuetify.breakpoint.lgAndUp ? '555px' : '350px'"
                                :height="$vuetify.breakpoint.lgAndUp ? '379px' : '250px'"
                                eager
                                contain
                            >
                            </v-img>
                        </v-col>
                        <!-- Image -->

                        <!-- Text -->
                        <v-col
                            cols="6"
                            lg="5"
                            offset="0"
                            offset-lg="1"
                        >
                            <!-- Testimonial -->
                            <v-row
                                style="color: #2E567A;"
                                align="center"
                            >
                                <v-col
                                    cols="12"
                                    :class="$vuetify.breakpoint.lgAndUp ? 'rob-20-m' : 'rob-16-m'"
                                    v-html="item.text"
                                />
                            </v-row>
                            <!-- Testimonial -->

                            <!-- Customer's Details -->
                            <v-col
                                cols="12"
                                style="color: #2E567A;"
                                :class="$vuetify.breakpoint.lgAndUp ? 'rob-16-r' : 'rob-12-r'"
                                class="pa-0 pt-8"
                                v-html="item.name"
                            />
                            <!-- Customer's Details -->
                        </v-col>
                        <!-- Text -->
                    </v-row>
                </v-carousel-item>
            </v-carousel>
            <!-- Testimonial Carousel -->
        </v-col>
    </v-row>
    <!-- Desktop -->
</template>
<script>
    export default {
        name: 'LandingTestimonialsComponent',

        data () {
            return {
                backgroundImages: [
                    {
                        desktop: '/landing/main/testimonials/desktop/LandingMainTestimonialsDesktopBackground_1.webp',
                        mobile: '/landing/main/testimonials/mobile/LandingMainTestimonialsMobileBackground_1.webp',
                        text: '“Fixer has allowed us to grow our <br /> business by having access to the right <br /> talent, at the right time and the right price. <br /> Qualified ‘Fixers’ have helped with daily <br /> finance tasks, project management and <br /> even managing our business travel”',
                        name: 'Joseph Malaka & Obuja Lemakwe<br />Founders - J&O Electronics - Nigeria'
                    }
                ],
                backgroundImagesMobile: [
                    {
                        desktop: '/landing/main/testimonials/desktop/LandingMainTestimonialsDesktopBackground_1.webp',
                        mobile: '/landing/main/testimonials/mobile/LandingMainTestimonialsMobileBackground_1.webp',
                        text: '“Fixer has allowed us to grow our <br /> business by having access to the <br /> right talent, at the right time and <br /> the right price. Qualified ‘Fixers’ <br /> have helped with daily finance <br /> tasks, project management and <br /> even managing our business <br /> travel”',
                        name: 'Joseph Malaka & Obuja Lemakwe<br />Founders - J&O Electronics - Nigeria'
                    }
                ]
            }
        }
    }
</script>
<style scoped>
    /* >>> .v-window__prev, >>> .v-window__next {
        margin-left: 5px;
        margin-right: 5px;
    } */
</style>
